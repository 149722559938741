<template>
  <div class="redem-doule">
    <div class="content">
      <div class="total-title">{{ ecologicalReward.Individual }}</div>
      <div class="total-number">{{ totalRecord }}</div>
    </div>
    <div class="redemption-box">
      <div class="redemption-detail">
        <div v-for="(item, index) in dlegeList.amounts" :key="index">
          <div v-if="dlegeList.status[index]">
            <div class="detail-content">
              <div class="suocang-box">
                <div class="suocang">{{ ecologicalReward.Lock }}</div>
                <div class="suocang-time">{{ dlegeList.creatutils[index] }}</div>
              </div>
              <div class="suocang-box">
                <div class="suocang">{{ ecologicalReward.Unlock }}</div>
                <div class="suocang-time">{{ dlegeList.lockutils[index] }}</div>
              </div>
              <div class="suocang-box">
                <div class="suocang">{{ ecologicalReward.pledge }}</div>
                <div class="suocang-time">{{getDays((dlegeList.lockUtils[index]-dlegeList.createTime[index]))}}</div>
              </div>
            </div>
            <div class="detail-content">
              <div class="suocang-box">
                <div class="suocang">{{ ecologicalReward.quantity }}</div>
                <div class="suocang-time">{{ item }}</div>
              </div>
              <div class="suocang-box">
                <div class="suocang">{{ ecologicalReward.weight }}</div>
                <div class="suocang-time">
                  {{$gbUtils.formatBalance(dlegeList.effects[index] / item ) }}
                </div>
              </div>
              <div class="suocang-box">
                <div class="suocang">{{ ecologicalReward.Reward }}</div>
                <div class="suocang-time">{{ dlegeList.effects[index] }}</div>
              </div>
            </div>
            <div class="btn-box">
              <van-button class="van-btn"
                :disabled="handleReleaseTime(index)"
                @click="handleConfirmWithdrawal(index)"
                :loading="(loadingHarvest && loadingHarvestIndex ===index) ? true : false">
                {{ ecologicalReward.extract}}
              </van-button>
            </div>
            <div class="line"></div>
          </div>
        </div>
        <div class="no-data" v-if="totalRecord == 0">
          <NoneData />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reward from '../../../utils/rewardMebMusd' // factory
import NoneData from '../../../components/common/NoneData.vue'
export default {
  components: { NoneData },
  data () {
    return {
      dlegeList: {
        amounts: [],
        status: []
      },
      timeOut: 60,
      loadingHarvest: false,
      loadingHarvestIndex: 0,
      totalRecord: 0
    }
  },
  computed: {
    ecologicalReward () {
      return this.$t('ecologicalReward')
    }
  },
  methods: {
    init () {
      this.$emit('changeLoading', true)
      this.handleDlegeList()
    },
    handleReleaseTime (index) {
      const startTime = this.$moment().format('X') // 转毫秒 X:'秒'
      if (startTime > this.dlegeList.lockUtils[index] || startTime === this.dlegeList.lockUtils[index]) {
        return false
      } else {
        return true
      }
    },
    // 提取利息
    async  handleConfirmWithdrawal (index) {
      this.loadingHarvest = true
      this.loadingHarvestIndex = index
      const resp = await reward.withdraw(index)
      this.loadingHarvest = false
      if (!resp.success) return this.$toast('fail')
      this.init()
      this.totalRecord = 0
    },
    async handleDlegeList () {
      const resp = await reward.userAllDeposits()
      this.dlegeList = resp.result
      console.log(resp.result, 'source')
      this.dlegeList.amounts = resp.result.amounts.map((item) => {
        item = this.$web3.fromWei(item)
        return item
      })
      this.dlegeList.effects = resp.result.effects.map((item) => {
        item = this.$web3.fromWei(item)
        return item
      })
      this.dlegeList.creatutils = resp.result.createTime.map(item => {
        item = this.$moment(item * 1000).format('YYYY-MM-DD HH:mm')
        return item
      })
      this.dlegeList.lockutils = resp.result.lockUtils.map((item) => {
        item = this.$moment(item * 1000).format('YYYY-MM-DD HH:mm')
        return item
      })
      for (let i = 0; i < this.dlegeList.effects.length; i++) {
        if (resp.result.status[i] === true) {
          this.totalRecord += parseFloat(this.dlegeList.effects[i])
        }
      }
      this.$emit('changeLoading', false)
    },
    getDays (second) {
      return this.$gbUtils.formatBalance(second / 86400)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.redem-doule {
  .content {
    padding-top: 26px;
    text-align: center;
    .total-title {
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.3);
    }
    .total-number {
      line-height: 36px;
      font-size: 24px;
      font-weight: 500;
      color: #000000;
    }
  }
  .redemption-box {
    margin-top: 25px;
    padding: 0px 12px;
    height: calc(100vh - 106px);
    overflow: hidden;
    overflow: scroll;
    .redemption-detail {
      padding: 0px 12px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 0;
      opacity: 1;
      .detail-content {
        padding-top: 24px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .suocang-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 33.33%;
          text-align: center;
          .suocang {
            line-height: 18px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.3);
          }
          .suocang-time {
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            word-wrap: break-word;
            width: 100%;
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .btn-box {
        text-align: center;
        margin-top: 24px;
        .van-btn {
          width: 102px;
          height: 36px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #25e2b1;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          font-weight: 500;
          color: #25e2b1;
        }
      }
      .line {
        margin-top: 24px;
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 0px 0px;
      }
    }
    .no-data {
      width: 100%;
      min-height: 450px;
      background: #ffffff;
    }
  }
}
</style>
